import React from 'react';

function GameMessage({message}) {
  return (
    <div className="gameMessage">
      {message}
    </div>
  );
}

export default GameMessage;
