import React from 'react';

function Score({value, timer}) {
  return (
    <div className="Score">
      <span>Score: {value}</span>
      <span className="timerText">Timer: {timer}s</span>
    </div>
  );
}

export default Score;
