import React from 'react';

function Button({text, action, className}) {
  let classes = className;
  return (
    <button className={classes} onClick={action}>
      {text}
    </button>
  );
}

export default Button;
