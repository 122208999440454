import React from 'react';
import logo from './logo.svg';


function Player({y}) {
  return (
    <img style={{top: y}} src={logo} className="App-logo" alt="logo" />
  );
}

export default Player;
