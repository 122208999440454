import React from 'react';
import './App.css';
import {useEffect, useState} from 'react';

import TheZone from './TheZone';
import GameMessage from './GameMessage';
import Score from './Score';
import Button from './Button';
import Player from './Player';

const Speed = 9;
const StartLocation = 100;
const MinimumScore = 8;
const TapJump = 30
const ZoneBottomY = 150;

function App() {
  const [isActive, setIsActive] = useState(false);
  const [playerY, setPlayerY] = useState(StartLocation);
  const [score, setScore] = useState(0);
  const [countdown, setCountdown] = useState(15);
  const [lastTimer, setLastTimer] = useState(15);
  const [message, setMessage] = useState("");
  const [height, setHeight] = useState(window.innerHeight);

  const tap = () => {
    setPlayerY(playerY - TapJump);
  };

  const start = () => {
    setScore(0);
    setCountdown(15);
    setLastTimer(0);
    setPlayerY(StartLocation);
    setIsActive(true);
  }

  const showInitialMessage = () => {
    setMessage(
      "The goal of the game is to keep React's logo within the red zone " +
      "for at least 8 seconds, would you be able to do it?\n\n" +
      "Press 'Start' to play the game!"
    )
  }

  // App Init State
  useEffect(() => {
    showInitialMessage();
  }, []);

  // Timer Effect
  useEffect(() => {
    let intervalId;
    if (isActive && countdown > 0) {
      intervalId = setInterval(() => {
        setCountdown(countdown - 1);
      }, 1000);
    }
    return () => clearInterval(intervalId);
  }, [isActive, countdown]);

  // Effect to kickstart and update object movement
  useEffect(() => {
    let intVal;
    if (isActive) {
      intVal = setInterval(() => {
        setPlayerY((playerY) => playerY + Speed);
      }, 100);
    }
    return () => clearInterval(intVal);
  }, [isActive]);

  // Effect to update game state based on object position
  useEffect(() => {
    if (isActive) {
      if (playerY < ZoneBottomY) {
        if (lastTimer > 0) {
          setScore((score) => score + Math.abs(lastTimer - countdown));
        }
        setLastTimer(countdown);
      }
      else if (playerY > height) {
        setIsActive(false);
        setMessage("Nice try! Press 'Start' to try one more time!")
      }
      else {
        if (countdown > 0) {
          setLastTimer(0);
        }
        else {
          setIsActive(false);
          if (score >= MinimumScore) {
            setMessage(`${score}\nseconds\n\nCongrats! You got a high score!`);
          }
          else {
            setMessage("Let's give it another try");
          }
          setPlayerY(StartLocation);
        }
      }
    }
  }, [playerY]);

  return (
    <div className="App">
      <header className="App-header">
        <Player y={playerY} />
        <Score value={score} timer={countdown} />
        { isActive && <TheZone /> }
        { isActive || <GameMessage message={message} /> }
        { isActive && <Button
          text="Tap"
          className="Button tapButton"
          action={tap}
        /> }
        { isActive || <Button
          text="Start"
          className="Button startButton"
          action={start}
        /> }
      </header>
    </div>
  );
}

export default App;

